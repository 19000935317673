import React, { useState } from "react"
import { Link } from "gatsby"

import { Collapse  } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images
import Masterblock_001 from "../images/images/masterblock/MasterBlock_3623_Face_fd_noir_sans_reflet.jpg"

import Masterblock_002 from "../images/images/masterblock/Corr_Dos_PR_variante_2_fd_noir.jpg"
import Masterblock_003 from "../images/images/masterblock/Corr_Dos_tech variante_1_fd_noir.jpg"
import Masterblock_004 from "../images/images/masterblock/Corr_Face_tech_fd_noir.jpg"
import Masterblock_005 from "../images/images/masterblock/Corr_PR_recto_verso_variante_1_fd_noir.jpg"
import Masterblock_006 from "../images/images/masterblock/MASERBLOCK_1.jpg"
import Masterblock_007 from "../images/images/masterblock/MASTERBLOCK_2.jpg"
import Masterblock_008 from "../images/images/masterblock/MASTERBLOCK_4.jpg"
import Masterblock_009 from "../images/images/masterblock/MASTERBLOCK_3.jpg"
import Masterblock_010 from "../images/images/masterblock/MasterBlock_3623_Dos_fd_noir_sans_reflet.jpg"
import Masterblock_011 from "../images/images/masterblock/Mouvement_fd_noir.jpg"

import { Button, Input, Modal, Tag } from "antd"

const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { Panel } = Collapse;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Gvchiani Genève"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Gvchiani Genève</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>
          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Gvchiani Genève</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro" style={{ marginBottom: 0 }}>
                <img className="img gvcgiani-img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Masterblock_001} alt="Gvchiani Genève"/>
                <p className="p-2 p-font">
                  Vous avez choisi une montre GVCHIANI, désormais vous entrez dans un nouvel univers…<br/>

                  Crée au concept de l’inconnu, chacune d’entre elles montre sa force caractériel.<br/>

                  Le défi relevé par l’entreprise touche de près une technique aux résultats novateurs, comme à une création
                  esthétique originale. Il en résulte un investissement sérieux en termes de temps et de réalisation. En ces
                  conditions, seule une qualité irréprochable avec une grande réactivité efficace sont à même de donner
                  entière satisfaction.
                </p>
              </div>
              <p className="p-2 p-font">
                Shant Ghouchian crée la Big Square titane à édition limitée, composée de 83 pièces. Une montre qui se
                définit par son nouveau design masculin moderne immédiatement reconnaissable, imposant hors norme et
                singulier, alliant l’élégance virile à la sportivité.<br/>

                Elle se démarque par sa finition, sa qualité, et sa glace en saphir originale en forme de X représentant
                l’inconnu.<br/>

                Cette fusion entre l’inconnu et la montre est pour nous l’occasion de découvrir des choses nouvelles, de
                vivre de nouvelles expériences en se confrontant à l’altérité du monde.<br/>

                Une magnifique montre conduite par le lancement d’un compte à rebours caractériel.<br/>

                Notre but est de créer des montres, homme, femme, sportive, de luxe, avec à chaque fois un design
                exceptionnel, en utilisant différents matériaux, et en créant des versions serties de diamants.<br/>

                Les montres sont équipées avec des mouvements Sellita SW500 extrêmement performants avec toujours une
                conception novatrice et créative.<br/>

                En aucun cas, Shant Ghouchian désire faire du volume, l’objectif de vente est d’environ 1000 à 1500 montres
                à série limitée par année dans tous modèles confondus, modèles pour homme et femme, en titane, en carbone,
                en or rose, en or blanc, en saphir complet, et bien d’autres nouveaux matériaux encore jamais vues.<br/>

                Au final, la pièce doit être unique.<br/>

                Cette montre masculine possède un design inédit et extrêmement fort, avec un look imposant, viril et
                provocateur.<br/>
                La Big Square représente la montre classe du futur pour ceux qui recherchent un produit nouveau et
                différent.<br/>

                Shant Ghouchian, a pour but d’entretenir le lien entre le client et la marque, en amenant l’acquéreur à être
                fier de posséder une GVCHIANI dans l’élégance et de façon sportive, avec ce sentiment d’être unique et de
                porter une montre exceptionnelle à son poignet.<br/>

                Acquérir une GVCHIANI ne s’arrête pas à s’offrir une pièce qui brille de par sa beauté et sa qualité, mais a
                pour but de vous suivre tout au long de votre vie.<br/>
                La satisfaction de nos clients est la base de notre succès.<br/>

                La plus grande réussite n’est pas de ne jamais tomber, mais de se relever à chaque chute.<br/>
              </p>
              <h5 style={{color: '#333'}}>Gvchiani & Cryptolex</h5>
              <span className="h7">Première mondiale, création d’une montre suisse de luxe mécanique dans la Blockchain.</span>

              <p className="p-2 p-font">
                Pour la première fois au monde, une montre mécanique ne va pas simplement donner l’heure mais elle va laisser une trace infalsifiable dans l’Histoire.

                La maison GVCHIANI et la société Cryptolex se sont associées dans le cadre du développement de la montre dénommée MasterBlock en hommage au Bitcoin et aux technologies du Blockchain.
                Cette montre 100 % mécanique Swiss Made donne une nouvelle dimension au 12ème Art.

                Jamais, dans l’histoire de l’horlogerie, une montre de luxe n’a été créée de A-Z dans la Blockchain, caractérisant ainsi l’ADN singulier de chaque pièce.

                Une montre, même de série, devient alors aussi unique que son propriétaire. Unique car cette montre de luxe est protégée par un certificat lié à la Blockchain émis par Cryptolex la rendant ainsi infalsifiable.
              </p>


              <Collapse bordered={false} defaultActiveKey={['1']} style={{margin: '30px'}}>
                <Panel header="Histoire & Concept" key="1">
                  <span className="tab-pane">En 2015, les fondateurs de la maison GVCHIANI et de la société Cryptolex joignent leurs forces et créent une montre unique, tant par sa forme que par sa technologie en y intégrant les symboliques de la Blockchain et du Bitcoin : la MasterBlock.<br/><br/>
              Avec la nouvelle ère que représente cette révolution technologique, le choix d’un design hors norme avec une forte identité visuelle devient alors une évidence. Sa forme rectangulaire et ses dimensions généreuses, font de la MasterBlock, un chronographe qui se distingue au premier coup d’œil. L’adresse unique de la montre sur la Blockchain est inscrite sur le cadran de la MasterBlock.<br/><br/>
              Quelle que soit la position des aiguilles, l’un des quatre qrcode reste en permanence entièrement déchiffrable, ce qui permet, en outre, d’utiliser la MasterBlock comme un «paper wallet ».</span>
                </Panel>
                <Panel header="Description du produit" key="2">
                  <span className="tab-pane">Créativité et différence sont les caractéristiques des montres GVCHIANI.<br/><br/>

              Au-delà de son allure atypique en forme de « Block » de titane DLC, le tracé du X reflète le code monétaire du Bitcoin (XBT).<br/><br/>

              Le bracelet cuir, quant à lui, représente un réseau décentralisé : la « Chain ».<br/><br/>

              Le calibre chronographe SW500 à remontage automatique de Sellita est doté d’une réserve de marche de 48 heures, d’un affichage des aiguilles heures, minutes, secondes ainsi que d’un affichage date et jour. La masse oscillante de couleur noire est spécialement personnalisée.<br/><br/>

              Le X gravé sur la couronne, mis en valeur par une bague en titane, suscite l’attention et surtout la curiosité. Rehaussé des chiffres 3, 6, 9 et 12, le boîtier, étanche à 30m, est sublimé par la prouesse technologique qu’est une glace saphir bombée en forme de X.<br/><br/>

              -Chaque MasterBlock aura sa propre adresse Blockchain.<br/><br/>

              -Chaque étape de production sera gravée dans la Blockchain, permettant ainsi
              de connaître l'historique de chaque MasterBlock.<br/><br/>

              -Chaque MasterBlock est liée à la MasterBlock précédente créant ainsi la
                chaîne.</span>
                </Panel>
                <Panel header="Une montre infalsifiable!!" key="3">
                  <span className="tab-pane">La MasterBlock est une édition limitée qui sera produite du numéro 0000 au numéro 2009, année du lancement du Bitcoin. Parmi elles , deux pièces exceptionnelles seront mises aux enchères :<br/>
              la 0000 dénommée « Genesis » et la 2009 dénommée « Satoshi » en hommage au créateur du Bitcoin.</span>
                </Panel>
                <Panel header="A propos du créateur Shant Ghouchian" key="4">
                  <span className="tab-pane">Issu d’une famille d’artisans d’art depuis des générations, Shant Ghouchian a suivi les pas de ses ancêtres et fait ses études de sertisseur aux Arts Déco de Genève. Il met, durant quatre années, ses compétences et son savoir-faire, au service de la maison Patek Philippe. Très vite, une envie d’indépendance le pousse à se mettre à son compte. C’est ainsi, qu’en 2008, passionné par son art et riche de son savoir- faire, il crée sa propre entreprise de sertissage baptisée Jewelry et Watch Settings (JWS). Reconnu pour son talent et ses compétences par les plus grands noms de la joaillerie et de l’horlogerie, le succès est au rendez-vous.</span>
                </Panel>
                <Panel header="A propos de Cryptolex" key="5">
                  <span className="tab-pane">Cryptolex a été fondée par Alec Avedisyan ingénieur EPFL, crypto-prophète et Vicken Bayramian, juriste spécialisé dans le négoce de matières premières et dans la Blockchain. Pour eux, la technologie Blockchain est la plus grande révolution depuis le lancement de l’internet.<br/><br/>
              Cryptolex a vocation d’utiliser la Blockchain sous ses aspects les plus novateurs et fiables pour en tirer le meilleur dans de nombreux de domaines tels que l’horlogerie ou le négoce.<br/><br/>
                Le slogan de Cryptolex, « Post Tenebras Cryptos » illustre bien l’ancrage à l’identité genevoise des deux créateurs.</span>
                </Panel>
              </Collapse>



              {/*}
              <Tabs defaultActiveKey="1" className="tabs-horlogeries" style={{ marginBottom: '60px' }}>
                <TabPane tab="Histoire & Concept" key="1">
              <span className="tab-pane">En 2015, les fondateurs de la maison GVCHIANI et de la société Cryptolex joignent leurs forces et créent une montre unique, tant par sa forme que par sa technologie en y intégrant les symboliques de la Blockchain et du Bitcoin : la MasterBlock.
              Avec la nouvelle ère que représente cette révolution technologique, le choix d’un design hors norme avec une forte identité visuelle devient alors une évidence. Sa forme rectangulaire et ses dimensions généreuses, font de la MasterBlock, un chronographe qui se distingue au premier coup d’œil. L’adresse unique de la montre sur la Blockchain est inscrite sur le cadran de la MasterBlock.
              Quelle que soit la position des aiguilles, l’un des quatre qrcode reste en permanence entièrement déchiffrable, ce qui permet, en outre, d’utiliser la MasterBlock comme un «paper wallet ».</span>
                </TabPane>
                <TabPane tab="Description du produit" key="2">
              <span className="tab-pane">Créativité et différence sont les caractéristiques des montres GVCHIANI.

              Au-delà de son allure atypique en forme de « Block » de titane DLC, le tracé du X reflète le code monétaire du Bitcoin (XBT).

              Le bracelet cuir, quant à lui, représente un réseau décentralisé : la « Chain ».

              Le calibre chronographe SW500 à remontage automatique de Sellita est doté d’une réserve de marche de 48 heures, d’un affichage des aiguilles heures, minutes, secondes ainsi que d’un affichage date et jour. La masse oscillante de couleur noire est spécialement personnalisée.

              Le X gravé sur la couronne, mis en valeur par une bague en titane, suscite l’attention et surtout la curiosité. Rehaussé des chiffres 3, 6, 9 et 12, le boîtier, étanche à 30m, est sublimé par la prouesse technologique qu’est une glace saphir bombée en forme de X.

              -Chaque MasterBlock aura sa propre adresse Blockchain.

              -Chaque étape de production sera gravée dans la Blockchain, permettant ainsi
              de connaître l'historique de chaque MasterBlock.

              -Chaque MasterBlock est liée à la MasterBlock précédente créant ainsi la
                chaîne.</span>
                </TabPane>
                <TabPane tab="Une montre infalsifiable!!" key="3">
            <span className="tab-pane">La MasterBlock est une édition limitée qui sera produite du numéro 0000 au numéro 2009, année du lancement du Bitcoin. Parmi elles , deux pièces exceptionnelles seront mises aux enchères :
              la 0000 dénommée « Genesis » et la 2009 dénommée « Satoshi » en hommage au créateur du Bitcoin.</span>
                </TabPane>
                <TabPane tab="A propos du créateur Shant Ghouchian" key="4">
                  <span className="tab-pane">Issu d’une famille d’artisans d’art depuis des générations, Shant Ghouchian a suivi les pas de ses ancêtres et fait ses études de sertisseur aux Arts Déco de Genève. Il met, durant quatre années, ses compétences et son savoir-faire, au service de la maison Patek Philippe. Très vite, une envie d’indépendance le pousse à se mettre à son compte. C’est ainsi, qu’en 2008, passionné par son art et riche de son savoir- faire, il crée sa propre entreprise de sertissage baptisée Jewelry et Watch Settings (JWS). Reconnu pour son talent et ses compétences par les plus grands noms de la joaillerie et de l’horlogerie, le succès est au rendez-vous.</span>
                </TabPane>
                <TabPane tab="A propos de Cryptolex" key="5">
              <span className="tab-pane">Cryptolex a été fondée par Alec Avedisyan ingénieur EPFL, crypto-prophète et Vicken Bayramian, juriste spécialisé dans le négoce de matières premières et dans la Blockchain. Pour eux, la technologie Blockchain est la plus grande révolution depuis le lancement de l’internet.
              Cryptolex a vocation d’utiliser la Blockchain sous ses aspects les plus novateurs et fiables pour en tirer le meilleur dans de nombreux de domaines tels que l’horlogerie ou le négoce.
                Le slogan de Cryptolex, « Post Tenebras Cryptos » illustre bien l’ancrage à l’identité genevoise des deux créateurs.</span>
                </TabPane>
              </Tabs>
              */}


              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_001} data-attribute="SRL"><img className="img-gallery" src={Masterblock_001}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_002} data-attribute="SRL"><img className="img-gallery" src={Masterblock_002}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_003} data-attribute="SRL"><img className="img-gallery" src={Masterblock_003}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_004} data-attribute="SRL"><img className="img-gallery" src={Masterblock_004}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_005} data-attribute="SRL"><img className="img-gallery" src={Masterblock_005}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_005} data-attribute="SRL"><img className="img-gallery" src={Masterblock_005}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_006} data-attribute="SRL"><img className="img-gallery" src={Masterblock_006}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_007} data-attribute="SRL"><img className="img-gallery" src={Masterblock_007}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_008} data-attribute="SRL"><img className="img-gallery" src={Masterblock_008}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_009} data-attribute="SRL"><img className="img-gallery" src={Masterblock_009}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_010} data-attribute="SRL"><img className="img-gallery" src={Masterblock_010}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Masterblock_011} data-attribute="SRL"><img className="img-gallery" src={Masterblock_011}
                                                                          alt="Gvchiani Genève"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Gvchiani Genève') }>Nous contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm page={dataContact} />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
